import React, { useEffect, useState } from "react";
import { Card, Row, Col, ProgressBar, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaArrowUp, FaArrowDown, FaGem, FaCheck } from 'react-icons/fa';

const stateAbbreviations = {
  AL: "Alabama", AK: "Alaska", AZ: "Arizona", AR: "Arkansas", CA: "California",
  CO: "Colorado", CT: "Connecticut", DE: "Delaware", FL: "Florida", GA: "Georgia",
  HI: "Hawaii", ID: "Idaho", IL: "Illinois", IN: "Indiana", IA: "Iowa",
  KS: "Kansas", KY: "Kentucky", LA: "Louisiana", ME: "Maine", MD: "Maryland",
  MA: "Massachusetts", MI: "Michigan", MN: "Minnesota", MS: "Mississippi", MO: "Missouri",
  MT: "Montana", NE: "Nebraska", NV: "Nevada", NH: "New Hampshire", NJ: "New Jersey",
  NM: "New Mexico", NY: "New York", NC: "North Carolina", ND: "North Dakota", OH: "Ohio",
  OK: "Oklahoma", OR: "Oregon", PA: "Pennsylvania", RI: "Rhode Island", SC: "South Carolina",
  SD: "South Dakota", TN: "Tennessee", TX: "Texas", UT: "Utah", VT: "Vermont",
  VA: "Virginia", WA: "Washington", WV: "West Virginia", WI: "Wisconsin", WY: "Wyoming"
};

const Summary = ({ colleges, userSAT, userType }) => {
  const navigate = useNavigate();

  const [summary, setSummary] = useState({
    reach: 0,
    target: 0,
    safety: 0,
    averageSAT: 0,
    averageACT: 0,
    averageCost: 0,
    locationDistribution: {},
  });


  useEffect(() => {
    if (userSAT === null || userSAT === undefined || userSAT === '' || isNaN(userSAT)) return;

    if (colleges.length === 0) {
      setSummary({
        reach: 0,
        target: 0,
        safety: 0,
        averageSAT: 0,
        averageACT: 0,
        averageCost: 0,
        locationDistribution: {},
      });
      return;
    }

    let reach = 0, target = 0, safety = 0;
    let totalSAT = 0, totalACT = 0, totalCost = 0;
    let countSAT = 0, countACT = 0, countCost = 0;
    let locations = {};

    colleges.forEach(college => {
      const collegeSAT = parseInt(college.SAT_AVG);

      if (collegeSAT && !isNaN(collegeSAT)) {
        if (userSAT < collegeSAT - 100) {
          reach++;
        } else if (userSAT > collegeSAT + 100) {
          safety++;
        } else {
          target++;
        }
      }

      if (college.SAT_AVG && college.SAT_AVG !== "N/A" && !isNaN(parseInt(college.SAT_AVG))) {
        totalSAT += parseInt(college.SAT_AVG);
        countSAT++;
      }
      if (college.ACT_AVG && college.ACT_AVG !== "N/A" && !isNaN(parseInt(college.ACT_AVG))) {
        totalACT += parseInt(college.ACT_AVG);
        countACT++;
      }
      if (college.NPT4_PUB && college.NPT4_PUB !== "N/A" && !isNaN(parseInt(college.NPT4_PUB))) {
        totalCost += parseInt(college.NPT4_PUB);
        countCost++;
      } else if (college.NPT4_PRIV && college.NPT4_PRIV !== "N/A" && !isNaN(parseInt(college.NPT4_PRIV))) {
        totalCost += parseInt(college.NPT4_PRIV);
        countCost++;
      }

      if (college.STABBR) {
        const fullStateName = stateAbbreviations[college.STABBR] || college.STABBR;
        locations[fullStateName] = (locations[fullStateName] || 0) + 1;
      }
    });

    const total = reach + target + safety;
    const reachPercentage = total > 0 ? (reach / total) * 100 : 0;
    const targetPercentage = total > 0 ? (target / total) * 100 : 0;
    const safetyPercentage = total > 0 ? (safety / total) * 100 : 0;

    setSummary({
      reach: reachPercentage,
      target: targetPercentage,
      safety: safetyPercentage,
      averageSAT: countSAT > 0 ? Math.round(totalSAT / countSAT) : 0,
      averageACT: countACT > 0 ? Math.round(totalACT / countACT) : 0,
      averageCost: countCost > 0 ? Math.round(totalCost / countCost) : 0,
      locationDistribution: locations,
    });
  }, [colleges, userSAT]);

  const renderLocationDistribution = () => {
    const sortedLocations = Object.entries(summary.locationDistribution)
      .sort((a, b) => b[1] - a[1]);

    const isFrequent = sortedLocations.length > 5;
    const locationsToShow = isFrequent ? sortedLocations.slice(0, 5) : sortedLocations;

    return (
      <>
        <h6>Location Distribution</h6>
        {locationsToShow.map(([state, count], index) => (
          <p key={index} className="mb-1">{count} in {state}</p>
        ))}
      </>
    );
  };

  const renderSATDifference = () => {
    if (!summary.averageSAT || isNaN(summary.averageSAT)) return null;
  
    const difference = userSAT - summary.averageSAT;
    const percentageDifference = ((difference / summary.averageSAT) * 100).toFixed(2);
  
    if (difference === 0) {
      return (
        <span className="ms-2 text-secondary">
          <FaCheck /> Same as average
        </span>
      );
    }
  
    const isHigher = difference > 0;
  
    return (
      <span className={`ms-2 ${isHigher ? 'text-success' : 'text-danger'}`}>
        {isHigher ? <FaArrowUp /> : <FaArrowDown />} {Math.abs(percentageDifference)}% {isHigher ? 'higher' : 'lower'}
      </span>
    );
  };
  

  if (userSAT === null || userSAT === undefined || userSAT === '' || isNaN(userSAT)) {
    return (
      <Card className="card-one">
        <Card.Body className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '200px' }}>
          <h6 className="text-center mb-4">To use the summary you need to add your SAT score!</h6>
          <Button variant="outline-primary" onClick={() => navigate('/settings')}>Add Now</Button>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="card-college-list">
      <Card.Header>
        <Card.Title as="h6">Summary</Card.Title>
      </Card.Header>
      <Card.Body style={{ maxHeight: '80vh', overflowY: 'auto', position: 'relative' }}>
        <Row className="mb-3">
          <Col>
            <h6>Classification</h6>
            <ProgressBar>
              <ProgressBar variant="danger" now={summary.reach} key={1} label={`${Math.round(summary.reach)}%`} />
              <ProgressBar variant="warning" now={summary.target} key={2} label={`${Math.round(summary.target)}%`} />
              <ProgressBar variant="success" now={summary.safety} key={3} label={`${Math.round(summary.safety)}%`} />
            </ProgressBar>
            <div className="mt-2">
              <span className="badge bg-danger me-2">Reach: {Math.round(summary.reach)}%</span>
              <span className="badge bg-warning me-2">Target: {Math.round(summary.target)}%</span>
              <span className="badge bg-success">Safety: {Math.round(summary.safety)}%</span>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <h6>Number of colleges: {colleges.length}</h6>
          </Col>
        </Row>
        <div className={userType === 1 ? 'blur-content blur-overlay' : ''}>
          <Row className="mb-3">
            <Col>
              <h6>Average Scores</h6>
              <p>
                <strong>SAT:</strong> {summary.averageSAT || 'N/A'} 
                {renderSATDifference()}
              </p>
              <p><strong>ACT:</strong> {summary.averageACT || 'N/A'}</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <h6>Average Cost</h6>
              <p>{summary.averageCost ? `$${summary.averageCost.toLocaleString()}` : 'N/A'}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              {renderLocationDistribution()}
            </Col>
          </Row>
        </div>
        {userType === 1 && (
          <Button
            variant="primary"
            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            onClick={() => navigate('/plans')}
          >
            <FaGem className="me-2" />
            Upgrade
          </Button>
        )}
      </Card.Body>
      <style jsx="true">{`
        .blur-content {
          filter: blur(8px);
          position: relative;
        }
        .blur-overlay {
          position: relative;
        }
        .blur-overlay::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.7);
        }
      `}</style>
    </Card>
  );
};

export default Summary;