import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Modal, Form, Alert, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaQuestionCircle } from 'react-icons/fa'; // Importing the icon
import axios from 'axios';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';


export default function UserTicketPage() {

  useEffect(() => {
    document.title = "Support | YouElite";
  }, []);



  const [tickets, setTickets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newTicket, setNewTicket] = useState({ category_id: '', subject: '', description: '' });
  const [error, setError] = useState(null);
  const [skin, setSkin] = useState(localStorage.getItem('skin-mode') || 'light');
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [replies, setReplies] = useState([]);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [newReply, setNewReply] = useState('');
  const userId = localStorage.getItem('user_id');
  const userName = localStorage.getItem('user_name');
  


  // Placeholder FAQ data
  const popularFAQs = [
    { id: 1, question: "Popular FAQ Coming Soon....", link: "/" }
  ];

  useEffect(() => {
    fetchTickets();
    fetchCategories();
  }, []);

  const fetchTickets = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get('/tickets', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTickets(response.data.data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
      setError('Failed to fetch tickets. Please try again later.');
    }
  };


  const handleAddReply = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.post(`/tickets/${selectedTicket.id}/replies`, {
        content: newReply,
        user_id: userId // Send the user_id along with the reply
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      // Directly add the user information to the reply
      const newReplyWithUser = {
        ...response.data,
        user: {
          id: userId,
          name: userName
        }
      };
  
      // Update the replies state with the new reply and force re-render
      setReplies(prevReplies => {
        return [...prevReplies, newReplyWithUser];
      });
  
      // Clear the reply input field after the state has been updated
      setNewReply('');
      
    } catch (error) {
      console.error('Error adding reply:', error);
      setError('Failed to add reply. Please try again later.');
    }
  };
  
  
  

  const fetchTicketDetails = async (ticketId) => {
    try {
      const token = localStorage.getItem('auth_token');
      const ticketResponse = await axios.get(`/tickets/${ticketId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSelectedTicket(ticketResponse.data);
  
      const repliesResponse = await axios.get(`/tickets/${ticketId}/replies`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setReplies(repliesResponse.data);
  
      setShowTicketModal(true);
    } catch (error) {
      console.error('Error fetching ticket details:', error);
      setError('Failed to fetch ticket details. Please try again later.');
    }
  };
  
  



  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get('/ticket-categories', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to fetch categories. Please try again later.');
    }
  };

  const handleCreateTicket = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const token = localStorage.getItem('auth_token');
      await axios.post('/tickets', newTicket, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShowModal(false);
      setNewTicket({ category_id: '', subject: '', description: '' });
      fetchTickets();
    } catch (error) {
      if (error.response && error.response.data.error_code === 'PLAN_LIMIT_REACHED') {
        setError(error.response.data.message);
      } else {
        console.error('Error creating ticket:', error);
        setError('An error occurred while creating the ticket. Please try again.');
      }
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Support</li>
            </ol>
            <h4 className="main-title mb-0">Customer Support</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="mb-0">Support Tickets</h5>
                  <Button variant="primary" onClick={() => setShowModal(true)}>
                    Create New Ticket
                  </Button>
                </div>
                {error && <Alert variant="danger">{error}</Alert>}
                <Table responsive className="table-tickets mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Subject</th>
                      <th>Category</th>
                      <th>Status</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.map((ticket) => (
                      <tr key={ticket.id} onClick={() => fetchTicketDetails(ticket.id)} style={{ cursor: 'pointer' }}>
                        <td><span className="ff-numerals">{ticket.id}</span></td>
                        <td>{ticket.subject}</td>
                        <td>{ticket.category.name}</td>
                        <td>
                          <span className={`badge bg-${ticket.status === 'open' ? 'success' : ticket.status === 'in_progress' ? 'warning' : 'secondary'}`}>
                            {ticket.status}
                          </span>
                        </td>
                        <td>{new Date(ticket.created_at).toLocaleDateString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <FaQuestionCircle className="me-2 text-warning" />
                  <h5 className="mb-0">Popular FAQs</h5>
                </div>
                <p>Find answers to our most commonly asked questions. If you don't find what you're looking for, visit our full <Link to="/faq">FAQ page</Link>.</p>
                <ul className="list-unstyled faq-list">
                  {popularFAQs.map((faq) => (
                    <li key={faq.id} className="faq-item mb-2">
                      <Link to={faq.link} className="faq-link">
                        {faq.question}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateTicket}>
            <Form.Group className="mb-3">
              <Form.Label>Category</Form.Label>
              <Form.Select 
                value={newTicket.category_id} 
                onChange={(e) => setNewTicket({...newTicket, category_id: e.target.value})}
                required
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>{category.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control 
                type="text" 
                value={newTicket.subject} 
                onChange={(e) => setNewTicket({...newTicket, subject: e.target.value})}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3} 
                value={newTicket.description} 
                onChange={(e) => setNewTicket({...newTicket, description: e.target.value})}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit Ticket
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showTicketModal} onHide={() => setShowTicketModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ticket Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTicket ? (
            <div>
              <p><strong>ID:</strong> {selectedTicket.id}</p>
              <p><strong>Subject:</strong> {selectedTicket.subject}</p>
              <p><strong>Description:</strong> {selectedTicket.description}</p>
              <p><strong>Category:</strong> {selectedTicket.category.name}</p>
              <p><strong>Status:</strong> <span className={`badge bg-${selectedTicket.status === 'open' ? 'success' : selectedTicket.status === 'in_progress' ? 'warning' : 'secondary'}`}>{selectedTicket.status}</span></p>
              <p><strong>Assigned To:</strong> {selectedTicket.assigned_to ? selectedTicket.assigned_to : 'Not Assigned'}</p>
              <p><strong>Created At:</strong> {new Date(selectedTicket.created_at).toLocaleDateString()}</p>

              <hr />

              <h5>Replies</h5>
              {replies.length > 0 ? (
                replies.map(reply => (
                  <div key={reply.id} className="mb-3 reply">
                    <p><strong>{reply.user?.name || 'Unknown User'}:</strong> {reply.content}</p>
                    <p className="text-muted" style={{ fontSize: '0.8em' }}>{new Date(reply.created_at).toLocaleString()}</p>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No replies yet. Be the first to respond!</p>
              )}

              <Form onSubmit={handleAddReply}>
                <Form.Group className="mb-3">
                  <Form.Label>Add a Reply</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={newReply}
                    onChange={(e) => setNewReply(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit Reply
                </Button>
              </Form>
            </div>
          ) : (
            <p>Loading ticket details...</p>
          )}
        </Modal.Body>

        {/* Inline CSS for Modal */}
        <style>{`
          .replies-section {
            margin-top: 20px;
          }

          .reply {
            background-color: #f8f9fa;
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 10px;
          }

          .reply strong {
            display: block;
            margin-bottom: 5px;
          }

          .reply p {
            margin: 0;
          }

          .reply hr {
            margin-top: 15px;
            margin-bottom: 15px;
          }
        `}</style>
      </Modal>
    </React.Fragment>
  );
}
