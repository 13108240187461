import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Col, Row, Form, Modal, Nav, Toast, Table } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { format } from 'date-fns';

const API_BASE_URL = 'https://api.student.youelite.net/api';

const ScholarshipFinder = () => {

  useEffect(() => {
    document.title = "Scholarship Finder | YouElite";
  }, []);



  const [scholarships, setScholarships] = useState([]);
  const [savedScholarships, setSavedScholarships] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState({
    locations: [],
    gpa: '',
    isMeritBased: false,
    isNeedBased: false,
    degreeLevel: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedScholarship, setSelectedScholarship] = useState(null);
  const [showDetailedCriteria, setShowDetailedCriteria] = useState(false);
  const [totalScholarshipsValue, setTotalScholarshipsValue] = useState(0);
  const [averageScholarshipsValue, setAverageScholarshipsValue] = useState(0);
  const [totalScholarships, setTotalScholarships] = useState(0);
  const [scholarshipDeadlines, setScholarshipDeadlines] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [selectedLocations, setSelectedLocations] = useState([]);


  const pageSize = 5;

  const getAuthToken = () => {
    const user = localStorage.getItem("user");
    if (user) {
      const { token } = JSON.parse(user);
      return token;
    }
    return null;
  };

  const locationOptions = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
    { value: 'DC', label: 'Washington D.C.' }
  ];


  const serializeFilters = (filters) => {
    const serialized = Object.keys(filters).reduce((acc, key) => {
      // Special handling for booleans
      if (typeof filters[key] === 'boolean') {
        acc.append(key, filters[key] ? 'true' : 'false');
      } else if (key === 'locations') {
        filters[key].forEach(value => acc.append(key, value.value));
      } else if (Array.isArray(filters[key]) && filters[key].length > 0) {
        filters[key].forEach(value => acc.append(key, value));
      } else if (typeof filters[key] === 'object' && filters[key] !== null) {
        acc.append(key, JSON.stringify(filters[key]));
      } else if (filters[key] !== null && filters[key] !== undefined && filters[key] !== '') {
        acc.append(key, filters[key]);
      }
      return acc;
    }, new URLSearchParams());
    
    return serialized;
  };
  
  

  const fetchScholarships = useCallback(async () => {
    const token = getAuthToken();
    if (!token) return;
  
    const serializedFilters = serializeFilters({
      ...filter,
      locations: selectedLocations,  // Pass the selected locations
      searchQuery,  // Include the searchQuery
    });
  
    const params = new URLSearchParams({
      page: currentPage,
      pageSize,
    });
  
    serializedFilters.forEach((value, key) => {
      params.append(key, value);
    });
  
    const requestUrl = `${API_BASE_URL}/scholarships?${params.toString()}`;
  
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
  
      const { data, totalPages, totalScholarshipsValue, averageScholarshipsValue, totalScholarships } = response.data;
  
      setScholarships(data);
      setTotalPages(totalPages);
      setTotalScholarships(totalScholarships);
      setTotalScholarshipsValue(totalScholarshipsValue);
      setAverageScholarshipsValue(averageScholarshipsValue);
  
    } catch (error) {
      console.error('Error fetching scholarships:', error);
    }
  }, [searchQuery, filter, selectedLocations, currentPage]);
  
  
  
  
  const fetchScholarshipDeadlines = useCallback(async () => {
    const token = getAuthToken();  // Get the token for authenticated requests
    if (!token) return;

    const requestUrl = `${API_BASE_URL}/scholarships/deadlines`;

    try {
        const response = await axios.get(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        });

        const { scholarshipDeadlines } = response.data;

        // Update the state with the deadlines data
        setScholarshipDeadlines(scholarshipDeadlines);

    } catch (error) {
        console.error('Error fetching scholarship deadlines:', error);
    }
}, []);  // Empty dependency array ensures this function is memoized and won't change on every render


useEffect(() => {
    fetchScholarships();  // Existing function for fetching main scholarships
    fetchScholarshipDeadlines();  // New function for fetching deadlines
}, [fetchScholarships, fetchScholarshipDeadlines, selectedLocations]);


  const fetchSavedScholarships = useCallback(async () => {
    const token = getAuthToken();
    if (!token) return;

    try {
      const response = await axios.get(`${API_BASE_URL}/scholarships/saved`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setSavedScholarships(response.data);
    } catch (error) {
      console.error('Error fetching saved scholarships:', error);
    }
  }, []);

  useEffect(() => {
    fetchSavedScholarships();
  }, [fetchSavedScholarships]);

  const handleSaveScholarship = async (scholarship) => {
    const token = getAuthToken();
    if (!token) return;

    try {
      await axios.post(`${API_BASE_URL}/scholarships/save`, { scholarship_id: scholarship.id }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      fetchSavedScholarships();
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setNotificationMessage(`Scholarship "${scholarship.program_name}" is already in your list.`);
        setShowNotification(true);

        // Hide notification after 3 seconds
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      } else {
        console.error('Error saving scholarship:', error);
      }
    }
  };

  const isScholarshipSaved = (scholarship) => {
    if (!scholarship) return false; // Add this check
    return savedScholarships.some(saved => saved.scholarship.id === scholarship.id);
  };
  
  

  const handleRemoveSavedScholarship = async (scholarshipId) => {
    const token = getAuthToken();
    if (!token) return;

    try {
      await axios.delete(`${API_BASE_URL}/scholarships/saved/${scholarshipId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      fetchSavedScholarships();
    } catch (error) {
      console.error('Error removing saved scholarship:', error);
    }
  };

  const handleLocationChange = (selectedOptions) => {
    const updatedLocations = selectedOptions.map(option => ({
      state: option.value,
      country: 'US',  // If you always assume US
    }));
    setSelectedLocations(updatedLocations);
  };
  
  

  const calculateDaysRemaining = (closingDate) => {
    const today = new Date();
    const closing = new Date(closingDate);
    const differenceInTime = closing.getTime() - today.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };
  

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
    setTimeout(fetchScholarships, 500);
  };
  

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    // Ensure that boolean fields are properly set as boolean values
    const updatedValue = type === 'checkbox' ? checked : value;
  
    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: updatedValue
    }));
    
    setCurrentPage(1);
    fetchScholarships(); // Update scholarships with the new filters
  };
  
  
  const handlePageChange = (direction) => {
    setCurrentPage(prevPage => direction === 'next' ? prevPage + 1 : prevPage - 1);
    fetchScholarships();
  };

  const handleShowModal = (scholarship) => {
    setSelectedScholarship(scholarship);
    setShowDetailedCriteria(false); 
    setShowModal(true);
  };
  

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedScholarship(null);
  };

  const formatTotalValue = (value) => {
    if (value < 100000000) {
      return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else if (value < 1000000000) {
      return `${(value / 1000000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Millions`;
    } else {
      return `${(value / 1000000000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Billions`;
    }
  };



  const renderAwardAmount = (minimumAward, maximumAward) => {
    if (minimumAward && maximumAward) {
      return `Between $${minimumAward} and $${maximumAward}`;
    } else if (minimumAward) {
      return `At least $${minimumAward}`;
    } else if (maximumAward) {
      return `Up to $${maximumAward}`;
    } else {
      return 'Award amount not specified';
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const toggleDetailedCriteria = () => {
    setShowDetailedCriteria(prevState => !prevState);
  };

  const renderNoteworthyCharacteristics = (characteristics) => {
    return characteristics.map((item, index) => {
      const {
        legitimacyConcerns,
        requiresApplicationFee,
        applicationFeeAmount,
        applicationAppearsInsecure,
      } = item;

      return (
        <div key={index}>
          {legitimacyConcerns?.hasLegitimacyConcerns !== undefined && (
            <p><strong>Legitimacy Concerns:</strong> {legitimacyConcerns.hasLegitimacyConcerns ? 'Yes' : 'No'}</p>
          )}
          {requiresApplicationFee !== undefined && (
            <p><strong>Requires Application Fee:</strong> {requiresApplicationFee ? `Yes ($${applicationFeeAmount})` : 'No'}</p>
          )}
          {applicationAppearsInsecure !== undefined && (
            <p><strong>Application Appears Insecure:</strong> {applicationAppearsInsecure ? 'Yes' : 'No'}</p>
          )}
        </div>
      );
    });
  };

  const ScholarshipDeadlines = () => {
    const totalOpening = scholarshipDeadlines.reduce((sum, day) => sum + day.opening, 0);
    const totalClosing = scholarshipDeadlines.reduce((sum, day) => sum + day.closing, 0);

    const openingPercentage = totalScholarships > 0 ? ((totalOpening / totalScholarships) * 100).toFixed(1) : '0.0';
    const closingPercentage = totalScholarships > 0 ? ((totalClosing / totalScholarships) * 100).toFixed(1) : '0.0';
  
    return (
      <Card className="card-one">
        <Card.Header>
          <Card.Title as="h6">Scholarship Data (Next 7 Days)</Card.Title>
        </Card.Header>
        <Card.Body className="p-3">
          <div className="d-flex justify-content-between mb-3">
            <div className="text-center">
              <h3 className="mb-0 text-primary">{totalOpening}</h3>
              <p className="mb-0">Opening</p>
              <p className="mb-0 text-muted" style={{ fontSize: '0.875rem' }}>{openingPercentage}% of Total</p>
            </div>
            <div className="text-center">
              <h3 className="mb-0 text-danger">{totalClosing}</h3>
              <p className="mb-0">Closing</p>
              <p className="mb-0 text-muted" style={{ fontSize: '0.875rem' }}>{closingPercentage}% of Total</p>
            </div>
          </div>
          <Table responsive hover className="scholarship-deadline-table">
            <thead>
              <tr>
                <th>Date</th>
                <th className="text-center">Opening</th>
                <th className="text-center">Closing</th>
              </tr>
            </thead>
            <tbody>
              {scholarshipDeadlines.map((day, index) => (
                <tr key={index}>
                  <td>{format(new Date(day.date), 'MMM dd, EEE')}</td>
                  <td className="text-center">
                    <span className="badge bg-primary-subtle text-primary">{day.opening}</span>
                  </td>
                  <td className="text-center">
                    <span className="badge bg-danger-subtle text-danger">{day.closing}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Scholarship Finder</li>
            </ol>
            <h4 className="main-title mb-0">Scholarship Finder</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="4">
            <Row className="g-3">
              <Col xs="12" md="4" xl="12">
                <Card className="card-one card-product h-100">
                  <Card.Body className="d-flex flex-column justify-content-between p-3">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <div className="card-icon"><i className="ri-money-dollar-circle-line"></i></div>
                      <h6 className="fw-normal ff-numerals mb-0 text-success" style={{visibility: 'hidden'}}>+28.5%</h6>
                    </div>
                    <div>
                      <h2 className="card-value ls--1">Total: ${formatTotalValue(totalScholarshipsValue)}</h2>
                      <h4 className="card-value ls--1 mt-2">Average: ${Number(averageScholarshipsValue).toFixed(2)}</h4>
                      <label className="card-label fw-medium text-dark">Scholarships' Awards</label>
                    </div>
                    <div style={{visibility: 'hidden'}}>
                      <span className="d-flex gap-1 fs-xs">
                        <span className="d-flex align-items-center text-success">
                          <span className="ff-numerals" style={{visibility: 'hidden'}}>2.3%</span><i className="ri-arrow-up-line" style={{visibility: 'hidden'}}></i>
                        </span>
                        <span className="text-secondary" style={{visibility: 'hidden'}}>than last week</span>
                      </span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs="12" md="4" xl="12">
                <Card className="card-one card-product h-100">
                  <Card.Body className="d-flex flex-column justify-content-between p-3">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <div className="card-icon"><i className="ri-file-list-3-line"></i></div>
                      <h6 className="fw-normal ff-numerals mb-0 text-danger" style={{visibility: 'hidden'}}>-3.8%</h6>
                    </div>
                    <div>
                      <h2 className="card-value ls--1">Total: {totalScholarships}</h2>
                      <label className="card-label fw-medium text-dark">Scholarships Available</label>
                    </div>
                    <div style={{visibility: 'hidden'}}>
                      <span className="d-flex gap-1 fs-xs">
                        <span className="d-flex align-items-center text-danger"style={{visibility: 'hidden'}}>
                          <span className="ff-numerals">0.5%</span><i className="ri-arrow-down-line"style={{visibility: 'hidden'}}></i>
                        </span>
                        <span className="text-secondary">than last week</span>
                      </span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xl="8">
            <ScholarshipDeadlines />
          </Col>
          <Col xl="3" style={{display:"none"}}>
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Filter</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Location</Form.Label>
                      <Select
                        options={locationOptions.filter(option => option.value && option.label)} // Filter out any undefined values
                        isMulti
                        isSearchable
                        value={selectedLocations}
                        onChange={setSelectedLocations}
                        placeholder="Select States"
                      />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Need-based"
                      name="isNeedBased"
                      checked={filter.isNeedBased}
                      onChange={handleFilterChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Merit-based"
                      name="isMeritBased"
                      checked={filter.isMeritBased}
                      onChange={handleFilterChange}
                    />
                  </Form.Group>





                  <Form.Group className="mb-3">
                    <Form.Label>GPA</Form.Label>
                    <Form.Control
                      type="number"
                      name="gpa"
                      value={filter.gpa}
                      onChange={handleFilterChange}
                      step="0.01"
                      min="0"
                      max="4"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Degree Level</Form.Label>
                    <Form.Select
                      name="degreeLevel"
                      value={filter.degreeLevel}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Degree Level</option>
                      <option value="professionalCertification">Professional Certification</option>
                      <option value="undergraduate">Undergraduate</option>
                      <option value="postgraduate">Postgraduate</option>
                    </Form.Select>
                  </Form.Group>




                  <Button variant="primary" style={{visibility:'hidden'}} onClick={fetchScholarships}>Apply Filters</Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="9">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Available Scholarships</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <Form.Control
                  type="text"
                  placeholder="Search scholarships"
                  value={searchQuery}
                  onChange={handleSearch}
                  className="mb-3"
                />
                <div className="scholarship-list mt-3">
                  {scholarships.map((scholarship) => {
                    const hasLogo = scholarship?.logo_url && scholarship.logo_url.trim() !== '';
                    const daysRemaining = calculateDaysRemaining(scholarship.close_date);

                    return (
                      <Card key={scholarship.id} className="mb-3" onClick={() => handleShowModal(scholarship)}>
                        <Card.Body className="d-flex">
                          {hasLogo && (
                            <div className="scholarship-logo">
                              <img src={scholarship.logo_url} alt="Scholarship Logo" />
                            </div>
                          )}
                          <div className="scholarship-details" style={{ marginLeft: hasLogo ? '20px' : '0' }}>
                            <h6 className="fs-5">{scholarship?.program_name}</h6>
                            <p className="text-primary mb-1">{renderAwardAmount(scholarship?.scholarship_minimum_award, scholarship?.scholarship_maximum_award)}</p>
                            {scholarship.program_organization_name && (
                              <p><strong>Organizations:</strong> {scholarship.program_organization_name}</p>
                            )}
                            {scholarship.allowed_funding_use_description && (
                              <p><strong>Funding Use:</strong> {scholarship.allowed_funding_use_description}</p>
                            )}
                            {daysRemaining <= 7 && (
                              <p style={{ color: 'red' }}>
                                Warning: This scholarship is closing in {daysRemaining} day(s)!
                              </p>
                            )}
                            <Button
                              variant="outline-primary"
                              size="sm"
                              style={{ width: '100%' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSaveScholarship(scholarship);
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </div>

                <div className="pagination d-flex justify-content-between align-items-center mt-4">
                  <Button variant="secondary" onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>
                    Previous
                  </Button>
                  <span>Page {currentPage} of {totalPages}</span>
                  <Button variant="secondary" onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>
                    Next
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="3">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">My Favorites</Card.Title>
              </Card.Header>
              <div className="p-3">
                {savedScholarships.map((savedScholarship) => {
                  const daysRemaining = calculateDaysRemaining(savedScholarship.scholarship?.close_date);

                  return (
                    <div 
                      key={savedScholarship.id} 
                      className="scholarship-item mb-3"
                      onClick={() => handleShowModal(savedScholarship.scholarship)} 
                      style={{ cursor: 'pointer' }}  
                    >
                      <h6 className="fs-5">{savedScholarship.scholarship?.program_name}</h6>
                      <p className="text-primary mb-1">{renderAwardAmount(savedScholarship.scholarship?.scholarship_minimum_award, savedScholarship.scholarship?.scholarship_maximum_award)}</p>
                      {daysRemaining <= 7 && (
                        <p style={{ color: 'red' }}>
                          Warning: This scholarship is closing in {daysRemaining} day(s)!
                        </p>
                      )}
                      <div className="d-flex justify-content-between">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            openInNewTab(savedScholarship.scholarship?.program_url);
                          }}
                        >
                          Website
                        </Button>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation(); 
                            handleRemoveSavedScholarship(savedScholarship.scholarship_id);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>

            </Card>
          </Col>
        </Row>
        <Footer className="footer"/>
      </div>
      

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedScholarship?.program_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedScholarship?.blurb && <p>{selectedScholarship?.blurb}</p>}
          {(selectedScholarship?.scholarship_minimum_award || selectedScholarship?.scholarship_maximum_award) && (
            <p><strong>Award Amount:</strong> {renderAwardAmount(selectedScholarship?.scholarship_minimum_award, selectedScholarship?.scholarship_maximum_award)}</p>
          )}
          {selectedScholarship?.eligibility_criteria_description && (
            <p><strong>Eligibility:</strong> {selectedScholarship?.eligibility_criteria_description}</p>
          )}
          {selectedScholarship?.program_url && (
            <p><strong>Application URL:</strong> <a href={selectedScholarship?.program_url} target="_blank" rel="noopener noreferrer">{selectedScholarship?.program_url}</a></p>
          )}
          {selectedScholarship?.application_cycle && (
            <p><strong>Application Cycle:</strong> {selectedScholarship?.application_cycle}</p>
          )}
          {selectedScholarship?.open_date && (
            <p><strong>Open Date:</strong> {selectedScholarship?.open_date}</p>
          )}
          {selectedScholarship?.close_date && (
            <p><strong>Close Date:</strong> {selectedScholarship?.close_date}</p>
          )}

          {/* Noteworthy Characteristics Section */}
          {selectedScholarship?.noteworthy_application_characteristics && selectedScholarship.noteworthy_application_characteristics.length > 0 && (
            <div>
              <h5>Noteworthy Characteristics</h5>
              {renderNoteworthyCharacteristics(selectedScholarship.noteworthy_application_characteristics)}
            </div>
          )}

          {/* New Toggle Button for Detailed Criteria */}
          {selectedScholarship?.eligibility_criteria?.length > 0 && (
            <div>
              <Button variant="link" onClick={toggleDetailedCriteria} style={{ paddingLeft: 0 }}>
                {showDetailedCriteria ? "Show less" : "Show detailed criteria"}
              </Button>

              {showDetailedCriteria && (
                <div>
                  <h5>Eligibility Criteria</h5>
                  {selectedScholarship?.eligibility_criteria.map((criteria, index) => (
                    <div key={index}>
                      {criteria.age && <p><strong>Age:</strong> {criteria.age}</p>}
                      {criteria.activity && criteria.activity.length > 0 && (
                        <p><strong>Activity:</strong> {criteria.activity.map(act => act.activity).join(', ')}</p>
                      )}
                      {criteria.academics && criteria.academics.length > 0 && (
                        <p><strong>Academics:</strong> {criteria.academics.map(acad => `${acad.academicEligibility} ${acad.academicEligibilityValue}`).join(', ')}</p>
                      )}
                      {criteria.locations && criteria.locations.length > 0 && (
                        <p><strong>Locations:</strong> {criteria.locations.map(loc => `${loc.state}, ${loc.country}`).join(', ')}</p>
                      )}
                      {criteria.profession && criteria.profession.length > 0 && (
                        <p><strong>Profession:</strong> {criteria.profession.map(prof => prof.profession).join(', ')}</p>
                      )}
                      {criteria.demographics && criteria.demographics.length > 0 && (
                        <p><strong>Demographics:</strong> {criteria.demographics.join(', ')}</p>
                      )}
                      {criteria.degreeSeeking && criteria.degreeSeeking.length > 0 && (
                        <p><strong>Degree Seeking:</strong> {criteria.degreeSeeking.join(', ')}</p>
                      )}
                      {criteria.fieldsOfStudy && criteria.fieldsOfStudy.length > 0 && (
                        <p><strong>Fields of Study:</strong> {criteria.fieldsOfStudy.map(fos => fos.fieldName).join(', ')}</p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Button 
            variant="primary" 
            onClick={() => handleSaveScholarship(selectedScholarship)} 
            disabled={isScholarshipSaved(selectedScholarship)}  // Disable button if already saved
          >
            {isScholarshipSaved(selectedScholarship) ? 'Scholarship Saved' : 'Save Scholarship'}
          </Button>

        </Modal.Footer>
      </Modal>

      <Toast
        onClose={() => setShowNotification(false)}
        show={showNotification}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          top: 20,
          right: 20,
          minWidth: 200,
          zIndex: 1000,
          animation: 'fadeIn 0.5s, fadeOut 0.5s 2.5s'
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{notificationMessage}</Toast.Body>
      </Toast>

      <style>{`
        .scholarship-logo img {
          max-width: 100px;
          max-height: 100px;
          object-fit: cover;
        }

        .scholarship-details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
        }

        .scholarship-item img {
          max-width: 100px;
          max-height: 100px;
          object-fit: cover;
          margin-right: 15px;
        }

        .pagination span {
          font-weight: bold;
        }

        .card-one {
          border: 1px solid #ddd;
          border-radius: 5px;
          padding: 10px;
        }

        .card-one h6 {
          font-size: 1.2rem;
          margin-bottom: 10px;
        }

        .card-one p {
          margin-bottom: 10px;
        }

        .btn-icon {
          border: none;
          background: none;
        }

        .btn-icon i {
          font-size: 1.5rem;
        }

        .scholarship-deadline-table th,
        .scholarship-deadline-table td {
          padding: 0.5rem;
        }
        
        .scholarship-deadline-table .badge {
          font-size: 0.875rem;
          padding: 0.25em 0.5em;
        }
      `}</style>
    </React.Fragment>
  );
};

export default ScholarshipFinder;